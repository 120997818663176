<template>
    <div class="preview_area" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="report_section py-4">
            <div class="habit_container">
                <div class="section_header mt-5">
                    <h2>Leaderboard</h2>
                </div>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="leaderboard_wpr">
                    <div class="leaderboard">
                        <div class="board_ttl">Participation Leaderboard <i class="fas fa-trophy"></i></div>
                        <ul>
                            <template v-if="loader || leaderboardLoader">
                                <li class="leaderboard_record">
                                    <h5>Loading...</h5>
                                </li>
                            </template>
                            <template  v-else-if="leaderboard.participationContacts && leaderboard.participationContacts.length">
                                <li v-for="(participationContact, p) in leaderboard.participationContacts" :key="p">
                                    <span class="board_pos">{{p + 1}}</span>
                                    <img :src="participationContact.contact.photo" alt="">
                                    <h5>{{ participationContact.contact.name }}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{ participationContact.participation_score }}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - ( participationContact.participation_score * 0.7 )"/>
                                        </svg>
                                    </div>
                                </li>
                            </template>
                            <li class="leaderboard_record" v-else>
                                <h5>No Records Found</h5>
                            </li>
                        </ul>
                    </div>
                    <div class="leaderboard">
                        <div class="board_ttl">Habit Leaderboard <i class="fas fa-trophy"></i></div>
                        <ul>
                            <template v-if="loader || leaderboardLoader">
                                <li class="leaderboard_record">
                                    <h5>Loading...</h5>
                                </li>
                            </template>
                            <template v-else-if="leaderboard.habitContacts && leaderboard.habitContacts.length">
                                <li  v-for="(habitContact, h) in leaderboard.habitContacts" :key="h">
                                    <span class="board_pos">{{h + 1}}</span>
                                    <img :src="habitContact.contact.photo" alt="">
                                    <h5>{{ habitContact.contact.name }}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{ habitContact.habit_score }}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - ( habitContact.habit_score * 0.7 )"/>
                                        </svg>
                                    </div>
                                </li>
                            </template>
                            <li class="leaderboard_record" v-else>
                                <h5>No Records Found</h5>
                            </li>
                        </ul>
                    </div>
                    <div class="leaderboard overall">
                        <div class="board_ttl">Overall Leaderboard <i class="fas fa-trophy"></i></div>
                        <ul>
                            <template v-if="loader || leaderboardLoader">
                                <li class="leaderboard_record">
                                    <h5>Loading...</h5>
                                </li>
                            </template>
                            <template v-else-if="leaderboard.habitContacts && leaderboard.habitContacts.length">
                                <li  v-for="(overallContact, o) in leaderboard.overallContacts" :key="o">
                                    <span class="board_pos">{{o + 1}}</span>
                                    <img :src="overallContact.contact.photo" alt="">
                                    <h5>{{ overallContact.contact.name }}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{ overallContact.overall_score }}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - ( overallContact.overall_score * 0.7 )"/>
                                        </svg>
                                    </div>
                                </li>
                            </template>
                            <li class="leaderboard_record" v-else>
                                <h5>No Records Found</h5>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="section_header mt-4">
                    <h2>Manage Contacts</h2>
                    <button type="button" class="refresh_btn" @click="handleRefreshScores()"><i class="fa fa-sync" :class="{ 'fa-spin': refreshScoreLoader }"></i> {{ refreshScoreLoader ? 'Refreshing' : 'Refresh Score' }}</button>
                </div>
                <ul class="tab_row border-bottom mb-5">
                    <li :class="manageTab === 'active' ? 'active' : ''"  :disable="loader" @click="manageTab = 'active'">Active</li>
                    <li :class="manageTab === 'deleted' ? 'active' : ''" :disable="loader" @click="manageTab = 'deleted'">Deleted</li>
                </ul>
                <div class="tab_wpr" v-if="manageTab === 'active'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('active')"  v-click-outside="closePageFilter">
                                    Show {{ activeParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="activeParams.per_page" :type="2" :is-dropdown="true" ref="active-per-page-filter" />
                                </li>
                                <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                                    {{ activeFilterTitle }} <i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                        <ul>
                                            <li v-for="(filter, f) in filters" :key="f" @click="sortingFilter(filter, 'active')">
                                                {{ filter.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area">
                                    <input type="text" @input="isTyping = true" v-model.trim="activeParams.search" placeholder="Search"/>
                                    <button type="button" class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <!-- <li class="list_info">
                                    {{ activeContacts.from ? activeContacts.from : 0 }} - {{ activeContacts.to ? activeContacts.to : 0 }} of <span>{{ activeContacts.total ? activeContacts.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <!-- <div><line-loader v-if="loader" /></div> -->
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="show_header standard" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Submissions</th>
                                        <th>Participation</th>
                                        <th>Reminder</th>
                                        <th>Actions</th>
                                        <th>Habit</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="activeContacts.data && activeContacts.data.length">
                                    <tr v-for="(activeContact, a) in activeContacts.data" :key="a">
                                        <td>
                                            <div class="user_wpr" @click=" profile = true; selectedContact = activeContact.contact">
                                                <h4>{{ activeContact.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ activeContact.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(activeContact.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>{{ activeContact.submission_count ? activeContact.submission_count : 0 }} / {{ activeContact.occurrences_count ?  activeContact.occurrences_count : 0 }}</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;" title="Habit Score">
                                                <div class="inner_circle">
                                                    <div class="score">{{ activeContact.participation_score }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="88 - ( activeContact.participation_score * 0.88 )" />
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label :for="`activeContact-${a}`" class="switch_option capsule_btn">
                                                <input type="checkbox" :id="`activeContact-${a}`" :checked="activeContact.has_reminder == 1" @change="handleEnableDisable($event, activeContact)" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li v-if="moment(activeContact.updated_at).isBefore(moment(), 'day') && (activeContact.in_queue || activeContact.in_queue_2)" @click="handleRestartReminder(activeContact)"><i class="fas fa-redo" title="Restart Reminder"></i></li>
                                                <li v-if="!activeContact.deleted_at && activeContact.has_reminder" @click="handleResendReminder(activeContact, 2)" title="Resend Sms reminder"><i class="fas fa-comment"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!activeContact.deleted_at && activeContact.has_reminder" @click="handleResendReminder(activeContact, 1)" title="Resend email reminder"><i class="fas fa-envelope"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li v-if="!activeContact.deleted_at" @click="handleUnassignContact(activeContact)"><i class="fas fa-trash-alt danger" title="Delete"></i></li>
                                                <li v-else class="action_left"></li>
                                                <li title="Preview"><i @click="handleViewHabitReport(activeContact)" class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;" title="Habit Score">
                                                <div class="inner_circle">
                                                    <div class="score">{{ activeContact.habit_score }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="88 - ( activeContact.habit_score * 0.88 )" />
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <a @click="selectedContact = activeContact.contact; launchPad = true;"><span><img src="@/assets/images/launchpad.svg"></span></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="9" class="px-4 text-center empty_td">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>
                                {{ activeContacts.from ? activeContacts.from : 0 }} - {{ activeContacts.to ? activeContacts.to : 0 }} of <span>{{ activeContacts.total ? activeContacts.total : 0 }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab_wpr" v-if="manageTab === 'deleted'">
                    <div class="result_wpr new">
                        <div class="actions">
                            <ul class="left">
                                <li class="optionDrops sort_list" @click="togglePerPageFilter('deleted')"  v-click-outside="closeUnlimitedPageFilter">
                                    Show {{ deletedParams.per_page }}<i class="fas fa-angle-down"></i>
                                    <page-filter v-model="deletedParams.per_page" :is-dropdown="true" :type="2" ref="deleted-per-page-filter" />
                                </li>
                                <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                                    {{ deletedFilterTitle }} <i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                        <ul>
                                            <li v-for="(filter, f) in filters" :key="f" @click="sortingFilter(filter, 'deleted')">
                                                {{ filter.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul class="right">
                                <li class="search_area">
                                    <input type="text" @input="isTypingDeleted = true" v-model.trim="deletedParams.search" placeholder="Search"/>
                                    <button type="button" class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <!-- <li class="list_info">
                                    {{ deletedContacts.from ? deletedContacts.from : 0 }} - {{ deletedContacts.to ? deletedContacts.to : 0 }} of <span>{{ deletedContacts.total ? deletedContacts.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <!-- <div><line-loader v-if="loader" /></div> -->
                        <div class="contact_loader" v-if="loader"><quote-loader/></div>
                        <div class="scroll_table">
                            <table class="show_header standard" v-show="!loader">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Assigned At</th>
                                        <th>Submissions</th>
                                        <th>Participation</th>
                                        <th>Reminder</th>
                                        <th>Actions</th>
                                        <th>Habit</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="deletedContacts.data && deletedContacts.data.length">
                                    <tr v-for="(deletedContact, d) in deletedContacts.data" :key="d">
                                        <td>
                                            <div class="user_wpr" @click=" profile = true; selectedContact = deletedContact.contact">
                                                <h4>{{ deletedContact.contact.name }}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="email_wpr">{{ deletedContact.contact.email }}</div>
                                        </td>
                                        <td>
                                            {{ moment.utc(deletedContact.created_at).local().format('ll | LT') }}
                                        </td>
                                        <td>{{ deletedContact.submission_count ? deletedContact.submission_count : 0 }} / {{ deletedContact.occurrences_count ?  deletedContact.occurrences_count : 0 }}</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;" title="Habit Score">
                                                <div class="inner_circle">
                                                    <div class="score">{{ deletedContact.participation_score }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="88 - ( deletedContact.participation_score - 0.88 )" />
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label :for="`deletedContact-${d}`" class="switch_option capsule_btn">
                                                <input type="checkbox" :id="`deletedContact-${d}`" :checked="deletedContact.has_reminder == 1" disabled hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li title="Preview"><i class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;" title="Habit Score">
                                                <div class="inner_circle">
                                                    <div class="score">{{ deletedContact.habit_score }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="88 - ( deletedContact.habit_score * 0.88 )"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <a @click="selectedContact = deletedContact.contact; launchPad = true;"><span><img src="@/assets/images/launchpad.svg"></span></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="9" class="px-4 text-center empty_td">No Records Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>
                                {{ deletedContacts.from ? deletedContacts.from : 0 }} - {{ deletedContacts.to ? deletedContacts.to : 0 }} of <span>{{ deletedContacts.total ? deletedContacts.total : 0 }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="model_pagination px-0 pb-4">
                    <div class="pagination" v-show="activeContacts.total && manageTab == 'active'">
                        <pagination v-model="activeParams.page" :range-size="0" :pages="activeContacts.last_page" @update:modelValue="handleActivePagination" />
                    </div>
                    <div class="pagination" v-show="deletedContacts.total && manageTab === 'deleted'">
                        <pagination v-model="deletedParams.page" :range-size="0" :pages="deletedContacts.last_page" @update:modelValue="handleDeletedPagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <view-report v-model="habitReport" :habit-tracking="habitTracking" :contact-relation="selectedContact" />
    <launch-pad v-model="launchPad" :contact="selectedContact" />
    <profile-component v-model="profile" :contact="selectedContact" />
</template>

<script>
    import { defineAsyncComponent, resolveComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const ViewReport = defineAsyncComponent(() => import('@/pages/habit-tracking/components/ViewReport'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Habit Tracking Manage Contact',

        data () {
            return {
                manageTab: 'active',
                activeParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'overall_score',
                    order: 'desc',
                    habit_tracking_id: '',
                    status: 'active'
                },
                deletedParams: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'overall_score',
                    order: 'desc',
                    habit_tracking_id: '',
                    status: 'deleted',
                },
                isTyping: false,
                isTypingDeleted: false,
                habitReport: false,
                selectedContact: {},
                filters: [
                    { title: 'Highest Overall Score', field: 'overall_score', order: 'desc'},
                    { title: 'Highest Participation Score', field: 'participation_score', order: 'desc'},
                    { title: 'Highest Habit Score', field: 'Habit_score', order: 'desc'},
                    { title: 'Lowest Overall Score', field: 'overall_score', order: 'asc'},
                    { title: 'Lowest Participation Score', field: 'participation_score', order: 'asc'},
                    { title: 'Lowest Habit Score', field: 'Habit_score', order: 'asc'},
                ],
                activeFilterTitle: 'Highest Overall Score',
                deletedFilterTitle: 'Highest Overall Score',
                actionList: 'Highest Overall Score',
                deletedActionList: 'Highest Overall Score',
                launchPad: false,
                profile: false,
                mounted: false,
                moment,
            }
        },

        props: {
            modelValue: Boolean,
            habitTracking: Object,
            refreshHabitTracking: {
                type: Function,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            ViewReport,
            LaunchPad,
            ProfileComponent,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.mounted = true;

                    vm.resetForm();

                    setTimeout(function () {
                        vm.getAssignContacts(vm.activeParams);
                        vm.getAssignContacts(vm.deletedParams);
                    }, 100);
                }
            },

            'activeParams.per_page' () {
                const vm = this;

                vm.activeParams.page = 1;
                vm.getAssignContacts(vm.activeParams);
            },

            'deletedParams.per_page' () {
                const vm = this;

                vm.deletedParams.page = 1;
                vm.getAssignContacts(vm.deletedParams);
            },

            'activeParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.activeParams.search != null) {
                        if (vm.activeParams.search.length >= 2 || vm.activeParams.search.length === 0) {
                            vm.activeParams.page = 1;
                            vm.getAssignContacts(vm.activeParams);
                        }
                    }
                }
            },

            'deletedParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTypingDeleted = false;
                }, 1500);
            },

            isTypingDeleted (val) {
                const vm = this;

                if (!val) {
                    if (vm.deletedParams.search != null) {
                        if (vm.deletedParams.search.length >= 2 || vm.deletedParams.search.length === 0) {
                            vm.deletedParams.page = 1;
                            vm.getAssignContacts(vm.deletedParams);
                        }
                    }
                }
            },

            habitReport (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            activeContacts (val) {
                const vm  = this;

                if (vm.mounted) {
                    vm.getHabitLeaderboard({ id: vm.habitTracking.id });

                    vm.mounted = false;
                }
            }
        },

        computed: mapState({
            loader: state => state.habitTrackingModule.habitTrackingComponentLoader,
            activeContacts: state => state.habitTrackingModule.activeContacts,
            deletedContacts: state => state.habitTrackingModule.deletedContacts,
            leaderboard: state => state.habitTrackingModule.leaderboard,
            leaderboardLoader: state => state.habitTrackingModule.habitTrackingLeaderboardLoader,
            refreshScoreLoader: state => state.habitTrackingModule.refreshScoreLoader,
        }),

        methods: {
            ...mapActions ({
                getAssignContacts: 'habitTrackingModule/getAssignContacts',
                enableDisableReminder: 'habitTrackingModule/enableDisableReminder',
                resendReminder: 'habitTrackingModule/resendReminder',
                unassignContacts: 'habitTrackingModule/unassignContacts',
                getHabitLeaderboard: 'habitTrackingModule/getHabitLeaderboard',
                refreshHabitScores: 'habitTrackingModule/refreshHabitScores',
                restartReminder: 'habitTrackingModule/restartReminder',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            togglePerPageFilter (value) {
                const vm = this;
                const filter = vm.$refs[`${value}-per-page-filter`];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['active-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeUnlimitedPageFilter () {
                const vm = this;
                const filter = vm.$refs['deleted-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handleActivePagination () {
                const vm = this;

                vm.getAssignContacts(vm.activeParams);
            },

            handleDeletedPagination () {
                const vm = this;

                vm.getAssignContacts(vm.deletedParams);
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },

            sortingFilter (filter, type) {
                const vm = this;

                if (type == 'active') {
                    vm.activeFilterTitle  = filter.title;
                    vm.activeParams.field = filter.field;
                    vm.activeParams.order = filter.order;

                    vm.getAssignContacts(vm.activeParams);
                }

                if (type == 'deleted') {
                    vm.deletedFilterTitle  = filter.title;
                    vm.deletedParams.field = filter.field;
                    vm.deletedParams.order = filter.order;

                    vm.getAssignContacts(vm.deletedParams);
                }
            },

            resetForm () {
                const vm = this;

                vm.manageTab    = 'active';
                vm.activeParams = {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'overall_score',
                    order: 'desc',
                    habit_tracking_id: vm.habitTracking.id,
                    status: 'active'
                };

                vm.deletedParams = {
                    page: 1,
                    per_page: 5,
                    search: '',
                    field: 'overall_score',
                    order: 'desc',
                    habit_tracking_id: vm.habitTracking.id,
                    status: 'deleted',
                };
            },

            handleEnableDisable (e, contact) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${e.target.checked ? 'enable' : 'disable'} the reminder?`);

                options.preConfirm = function () {
                                        return vm.enableDisableReminder({ assign_id:contact.id, has_reminder: e.target.checked ? 1 : 0 }).then((result) => {
                                            vm.activeParams.search = '';
                                            vm.getAssignContacts(vm.activeParams);
                                        });
                                    };

                Swal.fire(options).then((result) => {
                    if (!result.isConfirmed) {
                        e.target.checked = !e.target.checked;
                    }
                });
            },

            handleResendReminder (contact, type) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to resend ${ type == 1 ? 'email' : 'SMS' } reminder for this contact?`);

                options.preConfirm = function () {
                                        const params = {
                                            assign_id: contact.id,
                                            contact_id: contact.contact_id,
                                            id: contact.habit_tracking_id,
                                            type
                                        };

                                        return vm.resendReminder(params);
                                    };

                Swal.fire(options);
            },

            handleUnassignContact (contact) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to stop habit tracking for this contact?`);

                options.preConfirm = function () {
                                        return vm.unassignContacts({ contacts: [contact.contact_id], habit_tracking_ids: [contact.habit_tracking_id] }).then((result) => {
                                            if (result) {
                                                vm.activeParams.search = '';
                                                vm.getAssignContacts(vm.activeParams);
                                                vm.refreshHabitTracking();
                                                vm.getHabitLeaderboard({ id: vm.habitTracking.id });
                                            }
                                        })
                                    };

                Swal.fire(options);
            },

            handleViewHabitReport (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.habitReport = true;
            },

            handleRefreshScores () {
                const vm    = this;
                const type  = vm.manageTab;

                vm.refreshHabitScores({type, habit: vm.habitTracking.id}).then((result) => {
                    if (result) {
                        if (type == 'active') {
                            vm.getAssignContacts(vm.activeParams);
                        }

                        if (type == 'deleted') {
                            vm.getAssignContacts(vm.deletedParams);
                        }
                    }
                });
            },

            handleRestartReminder (contact) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to restart habit tracking reminder for this contact?`);

                options.preConfirm = function () {
                                        return vm.restartReminder({ contact_id: contact.contact_id, habit_tracking_id: contact.habit_tracking_id }).then((result) => {
                                            if (result) {
                                                vm.activeParams.search = '';
                                                vm.getAssignContacts(vm.activeParams);
                                            }
                                        })
                                    };

                Swal.fire(options);
            },
        }
    }
</script>

<style scoped>
    .share_playbook .result_wpr {
        width: 100%;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i{
        color: #2f7eed;
    }

    .model_pagination {
        display: flex;
        padding: 0px 35px;
        justify-content: flex-end;
        align-items: center;
    }

    .action_list .action_left {
        padding-left: 21px;
    }

    .habit_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width:25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table td:not(:first-child, :last-child, :nth-child(2)) * {
        margin: 0 auto;
    }

    .result_wpr table td .score_circle {
        display: inline-block;
    }

    .result_wpr table td .score_circle .inner_circle .score {
        font-size: 11px;
        line-height: 13px;
    }

    .result_wpr table td .score_circle .inner_circle .score span {
        font-size: 9px;
        line-height: 11px;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 15px;
    }

    .share_playbook .tab_row, .report_section .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .share_playbook .tab_row li, .report_section .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after, .report_section .tab_row li:after {
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active, .report_section .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after, .report_section .tab_row li.active:after{
        left: 0;
    }

    .leaderboard_record {
        justify-content: center;
        height: 100%;
        border: 0;
    }
    .leaderboard_record h5{
        color: #999;
    }

    .leaderboard_record ul li {
        padding: 6px 15px;
        display: flex;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }
    .refresh_btn {
        padding: 8px 15px;
        background: #fff;
        border-radius: 15px;
        color: #121525;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        gap: 10px;
        align-items: center;
        margin: 0;
        cursor: pointer;
    }

    @media(max-width: 599px){
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: 100%;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            margin-left: auto;
        }
        .result_wpr.new .actions > ul li.search_area{
            margin: 5px 0 5px auto;
        }
    }
</style>
